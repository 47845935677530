import tradingRequest from './fetch';
import ApiAdapter from './adapter';
import { CacheApi } from '~/utils/cacheApi/index';
class TradingApi {
  setAuthCode(code) {
    // 设置 Authorization 请求头信息
    tradingRequest.setAuthCode(code);
  }

  @CacheApi()
  async getContractList(data) {
    const res = await tradingRequest.post('/api/v1/public/meta/getMetaData', data);
    return ApiAdapter.contractList.res(res);
  }

  // 請求歷史K線
  // params { productCode, priceType, klineType, pageNo, pageSize }
  async getKlineHistory(data) {
    const res = await tradingRequest.post(
      '/api/v1/public/quote/v1/getKline',
      ApiAdapter.klineHistoryList.req(data)
    );
    return ApiAdapter.klineHistoryList.res(res);
  }

  async getAccount(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/getAccount',
      ApiAdapter.account.req(data)
    );
    return ApiAdapter.account.res(res);
  }

  async getAccountAsset(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/getAccountAsset',
      ApiAdapter.accountAsset.req(data)
    );
    return ApiAdapter.accountAsset.res(res);
  }

  async createOrder(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/createOrder',
      ApiAdapter.createOrder.req(data)
    );
    return ApiAdapter.createOrder.res(res);
  }

  async closeAllPosition(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/closeAllPosition',
      ApiAdapter.closeAllPosition.req(data)
    );
    return ApiAdapter.closeAllPosition.res(res);
  }

  async reversePosition(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/reversePosition',
      ApiAdapter.reversePosition.req(data)
    );
    return ApiAdapter.reversePosition.res(res);
  }

  async moveMargin(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/moveMargin',
      ApiAdapter.moveMargin.req(data)
    );
    return ApiAdapter.moveMargin.res(res);
  }

  async updateLeverage(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/updateLeverageSetting',
      ApiAdapter.updateLeverage.req(data)
    );
    return ApiAdapter.updateLeverage.res(res);
  }

  async updateMode(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/updateModeSetting',
      ApiAdapter.updateMode.req(data)
    );
    return ApiAdapter.updateMode.res(res);
  }

  async updateAutoAppendMargin(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/updateAutoAppendMargin',
      ApiAdapter.updateAutoAppendMargin.req(data)
    );
    return ApiAdapter.updateAutoAppendMargin.res(res);
  }

  async adjustIsolatedPositionMargin(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/adjustIsolatedPositionMargin',
      ApiAdapter.updateAutoAppendMargin.req(data)
    );
    return ApiAdapter.adjustIsolatedPositionMargin.res(res);
  }

  /**
   * 修改限价委托订单的价格
   * @param data
   * @return {Promise<unknown>}
   */
  async updateOrderLimitPrice(data) {
    const res = await tradingRequest.post(
        '/api/v1/private/order/updateOrderLimitPrice',
        ApiAdapter.updateOrderLimitPrice.req(data)
    );
    return ApiAdapter.updateOrderLimitPrice.res(res);
  }

  async updateOrderTriggerPrice(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/updateOrderTriggerPrice',
      ApiAdapter.updateOrderTriggerPrice.req(data)
    );
    return ApiAdapter.updateOrderTriggerPrice.res(res);
  }

  async updateOpenTpSlTriggerPrice(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/updateOrderOpenTpslTriggerPrice',
      ApiAdapter.updateOpenTpSlTriggerPrice.req(data)
    );
    return ApiAdapter.updateOpenTpSlTriggerPrice.res(res);
  }

  async getHistoryOrder(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/v1/getHistoryOrderPage',
      ApiAdapter.getHistoryOrder.req(data)
    );
    return ApiAdapter.getHistoryOrder.res(res);
  }

  async getCurrentOrder(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/getActiveOrderPage2',
      ApiAdapter.getCurrentOrder.req(data)
    );
    return ApiAdapter.getCurrentOrder.res(res);
  }

  async cancelOrderById(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/cancelOrderById',
      ApiAdapter.cancelOrderById.req(data)
    );
    return ApiAdapter.cancelOrderById.res(res);
  }

  async cancelAllOrder(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/cancelAllOrder',
      ApiAdapter.cancelAllOrder.req(data)
    );
    return ApiAdapter.cancelAllOrder.res(res);
  }

  async getHistoryOrderDetailById(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/order/v1/getHistoryOrderFillTransactionPage',
      ApiAdapter.getHistoryOrderDetailById.req(data)
    );
    return ApiAdapter.getHistoryOrderDetailById.res(res);
  }

  // 历史仓位
  async getHistoryPositionList(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/v1/getHistoryPositionPage',
      ApiAdapter.getHistoryPositionList.req(data)
    );
    return ApiAdapter.getHistoryPositionList.res(res);
  }

  async getFinancialConditionList(data) {
    const res = await tradingRequest.post(
      '/api/v1/private/account/v1/getCollateralTransactionPage',
      ApiAdapter.getFinancialConditionList.req(data)
    );
    return ApiAdapter.getFinancialConditionList.res(res);
  }

  async getMultiLanguageItem(data) {
    const res = await tradingRequest.post(
      '/api/v1/public/meta/getMultiLanguageItem',
      ApiAdapter.getMultiLanguageItem.req(data)
    );
    return ApiAdapter.getMultiLanguageItem.res(res);
  }

  @CacheApi()
  async checkContractEnableDisplay() {

    const res = await tradingRequest.post(
      // '/api/v1/private/order/checkContractEnableDisplay',
      '/api/v1/private/order/checkContractDisplay',
      ApiAdapter.checkContractEnableDisplay.req({})
    );
    const ret =  ApiAdapter.checkContractEnableDisplay.res(res);
    return ret
  }

  /**
   * 合约Pro或者历史资金费率
   * @param data 查询参数如下
   * "contractId": "string",                合约ID
   * "pageNo": 0,                           分页编号
   * "pageSize": 0,                         分页大小
   * "filterBeginTimeInclusive": "string",  过滤的开始时间
   * "filterEndTimeExclusive": "string"     过滤的结束时间
   * @return {Promise<unknown>}
   */
  async getFundingHistory(data) {
    const res = await tradingRequest.post(
      '/api/v1/public/quote/v1/getFundingHistory',
      ApiAdapter.getFundingHistory.req(data)
    );
    return ApiAdapter.getFundingHistory.res(res);
  }

  /************************************* 跟单Pro接口 start **************************************************************/

  /**
   * 我的交易员列表
   * @param data
   */
  async myTraders(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/myTraders', data);
  }

  /**
   * 跟单全局配置信息
   * 在跟单 止盈止损时 需要用到
   * @param data
   * @returns
   * traceLeverage  number 跟单杠杠
   * defaultTraceRation  跟单比例
   * defaultTraceCount  跟单张数
   * minTraceRation 最小跟单比例
   * maxTraceRation 最大跟单比例
   * minTraceCount 最小跟单张数
   * maxTraceCount 最大跟单张数
   * maxHoldCount 最大持仓张数
   * minStopProfitRation 最小止盈比例
   * maxStopProfitRation 最大止盈比例
   * sliderMaxStopProfitRatio 滑竿最大止盈比例
   * minStopLossRation 最小止损比例
   * maxStopLossRation 最大止损比例
   * sliderMaxStopLossRatio 滑竿最大止损比例
   * openCurrentOrderStatus  当前带单是否展示  0是 1 否
   */
  async traceGlobalConfig(data) {
    return tradingRequest.get('/api/v1/public/trace/globalConfig', data);
  }

  /**
   * 登录人信息
   * @param data
   */
  async getTraceUserInfo(data) {
    return tradingRequest.post('/api/v1/private/trace/user/getTraceUserInfo', data);
  }

  /**
   * 排行榜数据 - 顶级
   * @param data
   */
  async topTraderListView(data) {
    return tradingRequest.post('/api/v1/public/trace/topTraderListView', data);
  }

  /**
   * 排行榜数据
   * @param data
   */
  async traderListView(data) {
    return tradingRequest.post('/api/v1/public/trace/traderListView', data);
  }

  /**
   * 我的跟随者列表(正在跟的跟随者)
   * @param data
   */
  async myTracers(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/myTracers', data);
  }

  /**
   * 收益明细(统计)
   * @param data
   */
  async profitList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/profitList', data);
  }

  /**
   * 历史分润(统计)
   * @param data
   */
  async profitHisList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/profitHisList', data);
  }

  /**
   * 历史分润明细列表
   * @param data
   */
  async profitHisDetailList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/profitHisDetailList', data);
  }

  /**
   * 待分润明细列表
   * @param data
   */
  async waitProfitDetailList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/waitProfitDetailList', data);
  }

  /**
   * 累积已分润列表
   * @param data
   */
  async totalProfitList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/totalProfitList', data);
  }

  /**
   * 我的（历史带单/历史跟单）
   * @param data
   */
  async historyList(data) {
    return tradingRequest.post('/api/v1/private/tracking/getHistoryOrderList', data);
  }

  /**
   * 我的交易员
   * @param data
   */
  async myBatchTrader(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/myBatchTrader', data);
  }

  /**
   * 取消跟随
   * @param data
   */
  async removeTrader(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/removeTrader', data);
  }

  /**
   * 跟单设置
   * @param data
   */
  async setBatchTraceConfig(data) {
    return tradingRequest.post('/api/v1/private/trace/user/setBatchTraceConfig', data);
  }

  /**
   * 个人设置信息
   * @param data
   */
  async getTraceProfile(data) {
    return tradingRequest.post('/api/v1/private/trace/user/getTraceProfile', data);
  }

  /**
   * 申请交易员
   * @param data
   */
  async applyTrader(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/applyTrader', data);
  }

  /**
   * 检查交易员审核状态
   * @param data
   */
  async queryApplyTraderStatus(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/queryApplyTraderStatus', data);
  }

  /**
   * 设置昵称(多语言)
   * @param data
   */
  async setEnNickName(data) {
    return tradingRequest.post('/api/v1/private/trace/user/setEnNickName', data);
  }

  /**
   * 设置简介（多语言）
   * @param data
   */
  async setEnProfile(data) {
    return tradingRequest.post('/api/v1/private/trace/user/setEnProfile', data);
  }

  /**
   * 设置带单合约
   * @param data
   */
  async setProductCode(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/setTracingContract', data);
  }

  /**
   * 自动取消跟随
   * @param data
   */
  async setAutoCancel(data) {
    return tradingRequest.post('/api/v1/private/trace/user/setAutoCancel', data);
  }

  /**
   * 空位提醒
   * @param data
   */
  async setRemindTrace(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/setRemindTrace', data);
  }

  /**
   * 交易员筛选条件列表
   * @param data
   */
  async sortConditionList(data) {
    return tradingRequest.get('/api/v1/public/trace/sortConditionList', data);
  }

  /**
   * 全局配置接口
   * @param data
   */
  async globalConfig(data) {
    return tradingRequest.get('/api/v1/public/trace/globalConfig', data);
  }

  /**
   * 交易员开启的交易对的合约列表的信息
   * @param data
   */
  async getUserTraceLevelSetting(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/getUserContractsInfo', data);
  }


  /**
   * 获取多语言的设置审核信息
   * @param data
   */
  async getMultiLanguage(data) {
    return tradingRequest.post('/api/v1/private/trace/user/getMultiLanguage', data);
  }

  /**
   * 跟单信息
   * @param data
   * @returns
   */
  async traderContractInfoList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/traderContractInfoList', data);
  }

  /**
   * 带单信息
   * @param data
   * @returns
   */
  async traceContractInfoList(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/traceContractInfoList', data);
  }

  /**
   * 邀请跟随者
   * @param data
   */
  async traderInvite(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/inviteFollowers', data);
  }


  /**
   * 同意/拒绝 资管员跟单邀请
   * @param data
   * @returns
   */
  async setAssetTradersFollowStatus(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/setAssetTradersFollowStatus', data);
  }

  /**
   * 交易员账户主页
   * @param data
   */
  async traderDetailPage(data) {
    return tradingRequest.post('/api/v1/private/trace/follower/getMyTradeDetail', data);
  }

  /**
   * 交易员移除跟随者
   * @param data
   */
  async removeTracer(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/removeTracer', data);
  }

  /**
   *资管员成績信息
   * @param data
   * @returns
   */
  async traderView(data) {
    return tradingRequest.post('/api/v1/public/trace/traderView', data);
  }

  /**
   * 当前带单查询
   * @param data
   */
  async currentList(data) {
    return tradingRequest.post('/api/v1/private/tracking/getOpenOrderList', data);
  }

  /**
   * 跟随者（已经在跟和跟过的）
   * @param data
   */
  async followerList(data) {
    return tradingRequest.post('/api/v1/private/trace/trader/followerList', data);
  }

  /**
   * 推荐交易员列表
   * @param data
   */
  async recommendTraderList(data) {
    return tradingRequest.post('/api/v1/public/trace/recommendTraderList', data);
  }

  /**
   * 平仓所有跟踪订单
   * @param data
   */
  async closeAllTrackingOrder(data) {
    return tradingRequest.post('/api/v1/private/tracking/closeAllTrackingOrder', data);
  }

  /**
   * 更新带（跟）单参数
   * @param data
   */
  async updateTrackingOrder(data) {
    return tradingRequest.post('/api/v1/private/tracking/updateTrackingOrder', data);
  }

  /**
   * 带（跟）单平仓
   * @param data
   */
  async closeTrackingOrder(data) {
    return tradingRequest.post('/api/v1/private/tracking/closeTrackingOrder', data);
  }

  /**
   * 获取特定语言文本
   * @param data
   */
  async getTargetLanguageText(data) {
    return tradingRequest.post('/api/v1/private/translate/getTargetLanguageText', data);
  }
  /************************************* 跟单Pro接口 end ****************************************************************/
}

const tradingApi = new TradingApi();

export default tradingApi;
